import React from 'react';
import Close from '../../images/icon/cross.svg';
import './index.scss'
export default ({ closeModal }) => {
    return <div className="about">
        <div className="header">
            <div>
                <span>Christmas in July</span>
                <hr />

            </div>
            {/* <img src={ Close } onClick={ () => closeModal() } /> */}
        </div>


        <p>Christmas in July catalogue is a compilation of local manufactures displaying authentic made in Jamaica products.<br></br>
            A significant number of these products are ideal for corporate gifting.</p> <br></br>


        <h1>Christmas in July 2020 Suppliers Listing</h1>
        <br></br>


        <div className="header">

        </div>


        <iframe src="https://docs.google.com/spreadsheets/d/e/2PACX-1vQm5EZv8MDvBJrTDbxCR1MwzgQiPDtQ95ZnMgmYISH7xqUXEpJoIqVDw6ALKBlco4Dh6CRkD7Zy3yxg/pubhtml?widget=true&amp;headers=false" width="100%" height="450" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>


        {/*<iframe src="https://docs.google.com/forms/d/e/1FAIpQLSd6EqH612VqIr9wIiDozqBZX1pdaf_sn5dlAAwIPGGHsGWPAg/viewform?embedded=true"
     width="100%" height="500" frameborder="1" marginheight="0" marginwidth="0">Loading…</iframe> */}


        <br></br>

        <br></br>

        <h3><strong>2019 Christmas in July Catalogue</strong></h3>
        <br></br>
        <div className="publications">
            <iframe
                className="iframe"
                src="https://e.issuu.com/anonymous-embed.html?u=tourismlinkagesnetwork&d=christmas_in_july_2019_issuu_version"
                frameBorder="0"
                allowFullScreen="true"
            >
            </iframe>
        </div>
        <br></br>
        <h3><strong>2018 Christmas in July Catalogue</strong></h3>
        <br></br>
        <div className="publications">
            <iframe
                className="iframe"
                src="https://e.issuu.com/anonymous-embed.html?u=jbdc&d=christmas_in_july_2018_issuu_versio"
                frameBorder="0"
                allowFullScreen="true"
            >
            </iframe>
        </div>




    </div >
}
