import React from 'react';
import Close from '../../images/icon/cross.svg';
import './index.scss'
import About1 from '../../images/about1.png';
import About2 from '../../images/about2.png';
import image1 from "../../images/TLN SPEED NETWORKING_4B_Buyers.jpg";
import image2 from "../../images/TLN SPEED NETWORKING_4C- Suppliers.jpg";
export default ({ closeModal }) => {
    return <div className="about">
        <div className="header">
            <div>
                <span>ABOUT TOURISM LINKAGES NETWORK SPEED NETWORKING</span>
                <hr />
            </div>
            {/* <img src={Close} onClick={() => closeModal()} /> */}
        </div>

        <div className="flex-container">
            <div>
                <img src={image1} />
                <a target='_blank' className="register-button" href={'https://www.eiseverywhere.com/ehome/514663'}>REGISTER NOW</a>
            </div>
            <div>
                <img src={image2} />
                <a target='_blank' className="register-button" href={'https://www.eiseverywhere.com/ehome/514663'}>REGISTER NOW</a>
            </div>

        </div>

        <div className="content">

            <div className="heading">Background</div>

            <p>

               The Speed Networking Event is a special networking event which is led by the Tourism Enhancement Fund through the Tourism Linkages Network in collaboration with Jamaica Hotel and Tourist Association, Jamaica Manufacturers’ and Exporters Association (JMEA), Jamaica Promotions Corporation (JAMPRO), Rural Agricultural Development Authority (RADA) and the Jamaica Business Development Corporation (JBDC). The event will be held on March 12, 2020  from 8:15am – 5pm at the Montego Bay Convention, Rose Hall, St James. The primary objective of the event is to strengthen linkages and increase business between local suppliers and players in the tourism sector.
  
  <br></br>  <br></br>
 
 The event will take the form of fifteen (15) minute meetings between Managing Directors/General Managers/CEOs of local supplier companies of products/services and Owners/Managers of properties, restaurants, attractions and other tourism entities throughout the course of the day. Additional representatives from core areas within the hotel’s operation such as Purchasing, Food and Beverage, Housekeeping, Maintenance, Guest Services who are in a position to make purchasing decisions will also be in attendance.
 
 
            </p>
            <div className="center">
                <div className="heading">Date</div>
                <div className="subHeading">March 12th 2020</div>


                <div className="heading">Time</div>
                <div className="subHeading">8:15 a.m. - 5:00 p.m.</div>
                <div className="heading">Cost</div>
                <div className="subHeading">$6000jmd (Per person + Meal Provided)</div>
                <div className="heading">Location</div>
                <div className="subHeading"> Monetgo Bay Convention Centre, Rose Hall, Montego Bay.</div>


                <br></br><br></br>

                <a className="register-button" target='_blank' href={'https://www.eiseverywhere.com/ehome/514663'}>REGISTER NOW</a>
                <br></br><br></br>
            </div>





        </div>

    </div>
}
