import React, { useState } from "react";
import "./index.scss";
import { Button, Carousel } from "react-bootstrap";
import image1 from "../../images/TLN SPEED NETWORKING_4B_Buyers.jpg";
import image2 from "../../images/TLN SPEED NETWORKING_4C- Suppliers.jpg";

function MicrositePopUp({ micrositeRef,handleClick }) {
  return (
    <div className="popup-overlay">
      <div ref={micrositeRef} className="microsite-modal">
        <div className="close-icon pr-2 pl-2 pt-2">
          <i className="fas fa-times"></i>
        </div>
        <Carousel interval={500000000} className="pop-up-carousel">
          <Carousel.Item>
            <img className="d-block w-100" src={image1} alt="First slide" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={image2} alt="Second slide" />
          </Carousel.Item>
        </Carousel>
        <div className="btn-wrapper">
          <Button className="register-button" onClick={handleClick}>
              Register Today
          </Button>
        </div>
      </div>
    </div>
  );
}
export default MicrositePopUp;
